<template>
    <div :style="'overflow-y: auto; height: '+boxHeight+'; width: '+width" id="big-box">
        <div
            v-for="(item, index) in list"
            class="msgCss"
            :style="{textAlign: item.align, clear: 'both'}"
        >
            <span v-if="item && item.align == 'left'">
                <robot-outlined style="font-size: 2em;float: left;"/>
                <span v-if="item && item.link == ''">&nbsp;{{item.text}}</span>
                <span v-else-if="item && item.link">&nbsp;<a :href="item.link" target="_blank">{{item.text}}</a></span>
            </span>
            <span v-if="item && item.align == 'right'">
                <user-outlined style="font-size: 2em;float: right;"/>
                <span>{{item.text}}</span>&nbsp;
            </span>
        </div>
    </div>
    <div :style="'margin-top: 15px;width: '+width">
        <a-input-group compact>
            <a-input placeholder="请输入内容" v-model:value="sendMsg" style="width: calc(100% - 100px)" />
            <a-button type="primary" @click="handleMsg" style="width: 100px">发送</a-button>
        </a-input-group>
    </div>
</template>
  
<script>

import {Button, Input, InputGroup} from 'ant-design-vue'
import {RobotOutlined, UserOutlined} from '@ant-design/icons-vue'

export default {
    name: 'RobotChat',
    components: {
        AButton: Button,
        AInput: Input,
        AInputGroup: InputGroup,

        RobotOutlined,
        UserOutlined
    },
    data() {
        return {
            sendMsg: "",
            list: [
                // { align: "left",text: "模拟信息返回",link: "http://www.baidu.com" },
            ],
            loading: false,
        }
    },
    props: {
        width: {
            default: "auto"
        },
        boxHeight: {
            default: "auto"
        }
    },
    created: function () {
        document.addEventListener("keydown", (e) => {
          let key = window.event.keyCode;
          if (key == 13 && !this.loading) {
            // 13是enter键的键盘码 如果等于13 就调用click的登录方法
            this.handleMsg();
          }
        });
    },
    methods: {
        async handleMsg() {
          if (this.sendMsg !== "") {
            this.loading = true;
            await this.list.push({ align: "right", text: this.sendMsg });
            await this.scrollTop();
            var msg = this.sendMsg;
            this.sendMsg = "";
            this.reply(msg);
          }
        },
        reply(msg) {
            const params = {
                msg: msg
            }
            var that = this
            this.axios.request({
                url: "/ai/chat",
                method: 'get',
                params: params
            }).then( res => {
                that.pushList(res.data.answer, "left", "")
            })
            .catch(() => {
                that.pushList("说啥呢", "left", "")
            })
            .finally(() => {
                that.loading = false;
            })
        },
        // 处理滚动条一直保持最上方
        scrollTop() {
          let div = document.getElementById("big-box");
          div.scrollTop = div.scrollHeight;
        },
        // 处理滚动条一直保持最上方
        pushList(text, align, link) {
            let listMsg = {
              align: align,
              text: text,
              link: link,
            };
            this.list.push(listMsg);
            this.scrollTop();
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .number {
        color: #999;
    }
</style>