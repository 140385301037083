import store from "../store/index";

export function beforeEach (to, from, next) {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  } else {
    document.title = store.state.siteName
  }
  next()
}

