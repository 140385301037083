<template>
    <div :id="chartId" :style="'width: '+width+'px;height:'+height+'px;'"></div>
</template>
  
<script>
import {init} from 'echarts'

export default {
    name: 'DataChart',
    data() {
        return {
            chartId: 'chart-' + crypto.randomUUID(),
            myChart: null
        }
    },
    props: {
        title: "",
        xAxis: {
            type: Object,
            required: true
        },
        series: {
            type: Array,
            required: true
        },
        width: {
            default: 600
        },
        height: {
            default: 400
        }
    },
    mounted() {
        this.newCharts()
        this.setOption()
    },
    methods: {
        newCharts() {
            this.myChart = init(document.getElementById(this.chartId), null, {
                width: this.width,
                height: this.height
            })
        },
        setOption() {
            var legends = []
            for (var i in this.series) {
                legends.push(this.series[i].name)
            }

            var option = {
                title: this.title,
                tooltip: {},
                legend: {
                    data: legends,
                },
                xAxis: this.xAxis,
                yAxis: {
                    axisLabel: {
                        formatter: function (value, index) {
                            var out = value
                            if (out > 100000000000) {
                                out = out/100000000000 + "千亿"
                            } else if (out > 100000000) {
                                out = out/100000000 + "亿"
                            } else if (out > 10000000) {
                                out = out/10000000 + "千万"
                            } else if (out > 10000) {
                                out = out/10000 + "万"
                            } 
                            return out;
                        }
                    }
                },
                series: this.series
            }
            console.log(option)
            this.myChart.setOption(option)
        }
    },
    updated() {
        this.myChart.dispose()
        this.newCharts()
        this.setOption()
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .number {
        color: #999;
    }
</style>