<template>
    <div class="container" :class="{'container-night': isNight}">
        <div class="browser" id="browser" :class="{'browser-scrollbar': !isMobile}">
            <icp></icp>
            <a-button type="primary" @click="showDrawer" class="choice-menu">
                <menu-outlined/>
            </a-button>
            <ul id="file_list">
                <li v-for="(file, key) in fileAll" :key="key">
                    <img :src="file.src == '' ? imageErrPng : file.src"
                         :alt="file.file.name"
                         :style="{ 'max-width': imgWidth + 'px'}"
                         :class="{'original_size': (file.src == '' || isOriginalSize)}"/>
                </li>
            </ul>
            <div v-show="showGotoTop" @click="gotoTop" class="goto-top" id="goto-top">
                <vertical-align-top-outlined/>
            </div>
        </div>
        <div class="draw">
            <a-drawer
                    title="选择"
                    getContainer=".draw"
                    :closable="false"
                    :visible="visible"
                    @close="onClose"
                    :class="{'drawer-night': isNight}"
            >
                <p>
                    <a-button type="primary" @click="clickFileInput" class="image-button" block>
                        选择图片
                    </a-button>
                    <input id="image-input" accept="image/*" type="file" multiple name="file" @change="loadImages"
                           class="image-input"/>
                </p>
                <p>
                    <a-switch v-model:checked="isDaytime" checked-children="白天" un-checked-children="黑夜"/>
                </p>
                <p>
                    图片宽度
                    <a-input-number v-model:value="imgWidth" :min="100" :max="3000" addon-after="像素"
                                    style="width: 150px"/>
                </p>
                
                <p>
                    原图宽小于{{imgWidth}}像素时
                    <a-switch v-model:checked="isOriginalSize" checked-children="要" un-checked-children="不"/>
                    显示原图大小
                </p>
                <br/>

                <a-divider orientation="left" orientation-margin="0px">
                    <a-popover trigger="hover" placement="bottom">
                        <template #content>
                            <p>导入后点击图片可进入预览模式</p>
                        </template>
                        预览模式配置
                    </a-popover>
                </a-divider>

                <p>
                    工具栏
                    <a-switch v-model:checked="viewerOptions.toolbar"/>
                </p>
                <p>
                    图片导航
                    <a-switch v-model:checked="viewerOptions.navbar"/>
                </p>
                <p>
                    图片标题
                    <a-switch v-model:checked="viewerOptions.title"/>
                </p>
                <p>
                    播放图片时：
                </p>
                <p>
                    &emsp;<a-radio-group v-model:value="viewerOptions.fullscreen" button-style="solid">
                    <a-radio-button :value="true">全屏模式</a-radio-button>
                    <a-radio-button :value="false">网页全屏</a-radio-button>
                </a-radio-group>
                </p>
                <p>
                    &emsp;切换间隔
                    <a-input-number v-model:value="viewerOptions.interval" :min="1" addon-after="ms"
                                    style="width: 150px"/>
                </p>
            </a-drawer>
        </div>
    </div>
</template>

<script>
    import {Button, Drawer, Switch, Divider, Popover, RadioGroup, RadioButton, InputNumber} from 'ant-design-vue'
    import {MenuOutlined, VerticalAlignTopOutlined} from '@ant-design/icons-vue'
    import 'ant-design-vue/dist/antd.css'
    import Icp from '@/components/icp'
    import imageErrPng from '@/assets/image_err.png'

    import 'viewerjs/dist/viewer.css'
    import Viewer from 'viewerjs';

    export default {
        name: 'ImageReader',
        components: {
            MenuOutlined,
            VerticalAlignTopOutlined,
            AButton: Button,
            ADrawer: Drawer,
            ASwitch: Switch,
            ADivider: Divider,
            APopover: Popover,
            ARadioGroup: RadioGroup,
            ARadioButton: RadioButton,
            AInputNumber: InputNumber,
            Icp
        },
        data() {
            return {
                fileAll: new Array(),
                visible: true,
                isDaytime: true,
                isMobile: false,
                showGotoTop: false,
                imageErrPng: imageErrPng,
                imgWidth: 1200,
                isOriginalSize: false,

                viewer: null,
                viewerOptions: {
                    toolbar: true,  // 显示工具栏
                    navbar: false,  // 显示图片导航
                    title: false,  // 显示图片标题
                    fullscreen: true,  // play时要不要全屏，不全屏就全网页
                    interval: 500  // play时图片滚动的时间间隔
                }
            }
        },
        computed: {
            isNight() {
                return !this.isDaytime
            }
        },
        watch: {
            viewerOptions: {
                handler() {
                    this.destroyImageViewer()
                    this.initImageViewer()
                },
                //immediate: true,
                deep: true
            }
        },
        created() {
            if (this._isMobile()) {
                this.isMobile = true;
            }
        },
        mounted() {
            var that = this
            document.getElementById('browser').addEventListener('scroll', function (event) {
                that.handleGotoTop(event.target.scrollTop, event.target.scrollHeight, event.target.clientHeight)
            }, false)
        },
        /*updated() {
            this.$nextTick(() => {
            })
        },*/
        methods: {
            showDrawer() {
                this.visible = true;
            },
            onClose() {
                this.visible = false;
            },
            clickFileInput() {
                document.getElementById('image-input').click();
            },
            gotoTop() {
                var el = document.getElementById('browser')
                el.scrollTop = -el.scrollHeight
            },
            handleGotoTop(scrollTop, scrollHeight, clientHeight) {
                if (scrollTop + scrollHeight - clientHeight > 400) {
                    this.showGotoTop = true
                } else {
                    this.showGotoTop = false
                }
            },
            loadImages(event) {
                let handlerImgNum = 0
                let handlerImgs = []
                const files = event.currentTarget.files
                for (let i = 0; i < files.length; i++) {
                    handlerImgs.push({
                        file: files[i],
                        src: '',
                    })
                    
                    this.getImageSrc(files[i], i)
                    .then((res) => {
                        handlerImgs[res.index].src = res.src
                    })
                    .finally(() => {
                        handlerImgNum = handlerImgNum + 1
                        if (handlerImgNum === files.length) {
                            this.fileAll.push(...handlerImgs)
                            this.$nextTick(() => {
                                if (!this.viewer) {
                                    this.initImageViewer()
                                } else {
                                    this.viewer.update()
                                }
                            })
                        }
                    })
                }
            },
            getImageSrc(file, index) {
                return new Promise((resolve) => {
                    const temp = {
                        index: index,
                        src: '',
                    }
                    if (window.FileReader) {
                        var fr = new FileReader()
                        fr.readAsDataURL(file)
                        fr.onload = function (e) {
                            temp.src = e.target.result
                            resolve(temp)
                        }
                    }
                })
            },
            initImageViewer() {
                if (this.fileAll.length == 0) {
                    ret
                }
                const viewerEl = document.getElementById('file_list');
                //初始化 viewerjs
                var that = this
                that.viewer = new Viewer(viewerEl, {
                    transition: false,
                    title: that.viewerOptions.title,
                    navbar: that.viewerOptions.navbar,
                    zoomOnWheel: false, // 滚轮缩放，
                    tooltip: false,  // 旋转/缩放提示比例
                    toolbar: that.viewerOptions.toolbar, // 工具栏
                    fullscreen: that.viewerOptions.fullscreen,  // play时要不要全屏，不全屏就全网页
                    interval: that.viewerOptions.interval,  // play时图片滚动的时间间隔
                    //keyboard: false,  // 键盘支持
                    //loop: false, // 是否循环
                    //movable: false, // 是否可移动图片
                    //rotatable: false, // 是否可旋转图片
                    //scalable: false,
                    //zoomable: false,
                    //button: false,
                    // zIndex: 2015,  //
                    // zIndexInline: 2015,
                    ready() {
                        // 给viewer添加个鼠标滚轮事件，实现滚动鼠标上下页
                        var that = this
                        this.viewer.viewer.addEventListener('mousewheel', function (event) {
                            if (event.wheelDelta > 0) {
                                // 上滚
                                that.viewer.prev(true)
                            } else {
                                // 下滚
                                that.viewer.next(true)
                            }
                        }, false)
                    }
                });
            },
            destroyImageViewer() {
                if (!this.viewer) {
                    return
                }
                if (this.viewer.viewer) {
                    this.viewer.viewer.removeEventListener("mousewheel", function () { }, false)
                }
                this.viewer.destroy()
                this.viewer = null
            },
            _isMobile() {
                let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
                return flag;
            }
        }
    }
</script>
<style src="./image_reader.css" lang="css" scoped></style>
