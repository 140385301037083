import axios from 'axios'
import VueAxios from 'vue-axios'

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: "http://www.futchar.com/api",
  //baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 6000 // 请求超时时间
})

// 异常拦截处理器
const errorHandler = (error) => {
//   if (error.response) {
//     const data = error.response.data
//     console.log(data)
//   }
  return Promise.reject(error)
}

// 请求拦截处理器
request.interceptors.request.use(config => {
 // const token = storage.get(ACCESS_TOKEN)
  // 如果 token 存在
  // 让每个请求携带自定义 token 请根据实际情况自行修改
//   if (token) {
//     config.headers['x-Token'] = token
//   }
  return config
}, errorHandler)

// 响应拦截处理器
request.interceptors.response.use((response) => {
  return response.data
}, errorHandler)

const installer = {
  vm: {},
  install (Vue) {
    Vue.use(VueAxios, request)
  }
}

export default request

export {
  installer as MyVueAxios,
  request as axios
}
