export var routes = [
  /*{
    path: '/',
    component: () => import('@/pages/home/home.vue'),
    hidden: true,
    meta: { title: '浮槎空间' },
  }*/
]

export default routes
