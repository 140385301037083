<template>
    <a-layout class="container" v-cloak>
        <a-layout-sider :width="520" theme="light">
            <div class="calculator">
                <a-divider orientation="left" style="font-size: 0.9em;">设置</a-divider>
                <a-input v-model:value="code" addonBefore="默认代码" />
                <a-space size="0">
                    <a-input v-model:value="chartSize.width" addonBefore="图表大小"/>
                    <a-input v-model:value="chartSize.height" addonBefore="x" addonAfter="px" />
                </a-space>

                <a-divider orientation="left" style="font-size: 0.9em;">变量</a-divider>
                <div class="variable-list">
                    <a-collapse>
                        <a-collapse-panel v-for="(variable, key) in variables" :key="key" :header="variable.title+' : '+ variable.code">
                            <a-button 
                            v-for="tag in variable.tags" 
                            class="var" 
                            type="primary" 
                            @click="copyField(variable.code, tag.bson)"
                            >
                            {{ tag.name }} : {{ tag.bson }}
                            </a-button>
                        </a-collapse-panel>
                    </a-collapse>
                </div>

                <a-divider orientation="left" style="font-size: 0.9em;">公式</a-divider>
                <div class="formula-list">
                    <a-button style="margin-bottom: 8px" @click="addDocItem('doc')">添加图表</a-button>
                    <a-list v-for="(doc, docIndex) in docs" bordered :data-source="doc.formulas">
                        
                        <template #header>
                            <a-space direction="vertical">
                                <div v-if="doc.isEditTitle" class="inline-block">
                                    <a-input v-model:value="doc.title" @pressEnter="saveDocItem('title', docIndex)" />
                                </div>
                                <div v-else class="inline-block">
                                    {{ doc.title }}
                                    <edit-outlined @click="editDocItem('title', docIndex)" />
                                </div>
                                <a-range-picker v-model:value="doc.date" />
                            </a-space>
                            <a-space style="float: right;" direction="vertical">
                            <a-select
                                ref="select"
                                v-model:value="doc.reportDate"
                                >
                                <a-select-option v-for="date in reportDates" :value="date.date">{{ date.name }}</a-select-option>
                            </a-select>
                            <delete-outlined style="float: right;" @click="removeDocItem('doc', docIndex)" />
                        </a-space>
                        </template>
                        <template #renderItem="{ item, index }">
                            <a-list-item>
                                <a-space>
                                    <div v-if="item.isEditName" class="inline-block">
                                        <a-input v-model:value="item.name" size="small" style="width:70px;" @pressEnter="saveDocItem('fName', docIndex, index)" />
                                    </div>
                                    <div v-else class="inline-block">
                                        <span class="forumla-text">{{ item.name }}</span>
                                        <edit-outlined @click="editDocItem('fName', docIndex, index)" />
                                    </div>
                                    =
                                    <a-tooltip>
                                        <template #title>{{ item.expr }}</template>
                                        <div v-if="item.isEditExpr" class="inline-block">
                                            <a-input v-model:value="item.expr" size="small" @pressEnter="saveDocItem('fExpr', docIndex, index)" />
                                        </div>
                                        <div v-else class="inline-block">
                                            <span class="forumla-text">{{ item.expr }}</span>
                                            <edit-outlined @click="editDocItem('fExpr', docIndex, index)" />
                                        </div>
                                    </a-tooltip>
                                    <div v-if="item.isEditCode" class="inline-block">
                                        <a-input v-model:value="item.code" size="small" style="width:75px;" @pressEnter="saveDocItem('fCode', docIndex, index)" />
                                    </div>
                                    <div v-else class="inline-block">
                                        <span class="forumla-text">{{ item.code }}</span>
                                        <edit-outlined @click="editDocItem('fCode', docIndex, index)" />
                                    </div>
                                    <a-select
                                        size="small"
                                        ref="select"
                                        v-model:value="item.chartType"
                                        style="width: 65px"
                                        >
                                        <a-select-option value="line">折线</a-select-option>
                                        <a-select-option value="bar">柱状</a-select-option>
                                    </a-select>
                                </a-space>
                                
                                <template #actions>
                                    <delete-outlined  @click="removeDocItem('formula', docIndex, index)" />
                                </template>
                            </a-list-item>
                        </template>
                        <template #footer>
                            <a-button style="margin-bottom: 8px" @click="addDocItem('formula', docIndex)">添加数据列</a-button>
                        </template>
                    </a-list>
                    
                    <div :style="{ textAlign: 'right' }" >
                        <a-button @click="getData()">Do it</a-button>
                    </div>
                </div>
            </div>
        </a-layout-sider>
        <a-layout>
            <a-layout-content style="m">
                <data-chart 
                class="inline-block" 
                v-for="item in charts" 
                :title="item.title" 
                :xAxis="item.xAxis" 
                :series="item.series" 
                :width="chartSize.width" 
                :height="chartSize.height"
                ></data-chart>
            </a-layout-content>
            <a-layout-footer><icp></icp></a-layout-footer>
        </a-layout>
    </a-layout>
</template>

<script>
    import {Row, Col, Button, List, ListItem, Input, InputGroup, Layout, LayoutSider, LayoutContent, LayoutFooter, 
        Select, SelectOption, Collapse, CollapsePanel, Divider, Space, RangePicker, Tooltip, message
    } from 'ant-design-vue'
    import {CloseSquareOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons-vue'
    import { ref } from 'vue'
    import DataChart from '@/components/data_chart'  
    import Icp from '@/components/icp'
    //https://v-charts.js.org


    export default {
        name: 'Report',
        components: {
            ARow: Row,
            ACol: Col,
            AButton: Button,
            AInput: Input,
            AInputGroup: InputGroup,
            AList: List,
            AListItem: ListItem,
            ALayout: Layout,
            ALayoutSider: LayoutSider,
            ALayoutContent: LayoutContent,
            ALayoutFooter: LayoutFooter,
            ASelect: Select,
            ASelectOption: SelectOption,
            ACollapse: Collapse,
            ACollapsePanel: CollapsePanel,
            ADivider: Divider,
            ASpace: Space,
            ARangePicker: RangePicker,
            ATooltip: Tooltip,

            CloseSquareOutlined,
            DeleteOutlined,
            EditOutlined,
            DataChart,
            Icp
        },
        data() {
            return {
                code: "",
                chartSize: {width: 600, height: 400},
                reportDates:[
                    {name: "全部", date: ""},
                    {name: "一季度", date: "03-31"},
                    {name: "半年", date: "06-30"},
                    {name: "三季度", date: "09-30"},
                    {name: "年报", date: "12-31"},
                ],
                variables:[
                    // {
                    //     title: "资产负债表",
                    //     code: "balance_sheet",
                    //     tags:[
                    //         {name: '收益率', code: 'shou'}
                    //     ]
                    // }
                ],
                docs:[
                    {
                        title: "",
                        reportDate: "12-31",
                        date: ref(),
                        formulas: [{name:"", expr: "", chartType: 'line', code: ""}],
                    }
                ],
                charts:[
                    // {
                    //     title: {
                    //         text: 'ECharts 入门示例'
                    //     },
                    //     xAxis: {
                    //         data: ['衬衫', '羊毛衫', '雪纺衫', '裤子', '高跟鞋', '袜子']
                    //     },
                    //     series: [
                    //         {
                    //             name: '销量',
                    //             type: 'bar',
                    //             data: [5, 20, 36, 10, 10, 20]
                    //         }
                    //     ]
                    // }
                ]
            }
        },
        mounted() {
            var that = this
            this.axios.request({
                url: "/stock/report/target",
                method: 'get',
                data: {}
            }).then(res=> {
                that.variables = res.data.rows
            })
            
        },
        methods: {
            copyField(table, field) {
                var val = table + "." + field
                let elInput = document.createElement('input')
                elInput.value = val
                document.body.appendChild(elInput)
                // 选择对象
                elInput.select()
                document.execCommand("Copy")
                message.success("已复制：" + val)
                elInput.remove()
            },
            addDocItem(key, dIndex) {
                if (key == "formula") {
                    this.docs[dIndex].formulas.push({
                        name: "",
                        formula: "",
                        chartType: "line",
                        code: this.code
                    })
                } else if (key == "doc") {
                    this.docs.push({
                        title: "",
                        reportDate: "12-31",
                        formulas: [],
                    })
                } 
                
            },
            removeDocItem(key, dIndex, fIndex) {
                if (key == "formula") {
                    this.docs[dIndex].formulas.splice(fIndex, 1)
                } else if (key == "doc") {
                    this.docs.splice(dIndex, 1)
                }
            },
            editDocItem(key, dIndex, fIndex) {
                if (key == "title") {
                    this.docs[dIndex].isEditTitle = true
                } else if (key == "fName") {
                    this.docs[dIndex].formulas[fIndex].isEditName = true
                } else if (key == "fExpr") {
                    this.docs[dIndex].formulas[fIndex].isEditExpr = true
                } else if (key == "fCode") {
                    this.docs[dIndex].formulas[fIndex].isEditCode = true
                }
            },
            saveDocItem(key, dIndex, fIndex) {
                if (key == "title") {
                    this.docs[dIndex].isEditTitle = false
                } else if (key == "fName") {
                    this.docs[dIndex].formulas[fIndex].isEditName = false
                } else if (key == "fExpr") {
                    this.docs[dIndex].formulas[fIndex].isEditExpr = false
                } else if (key == "fCode") {
                    this.docs[dIndex].formulas[fIndex].isEditCode = false
                }
            },
            getData() {
                if (this.docs.length == 0) {
                    message.error("没数据")
                    return
                }
                var docs = []
                for (var docIndex in this.docs) {
                    var doc = this.docs[docIndex]
                    var formulas = []
                    for (var fIndex in doc.formulas) {
                        var formula = doc.formulas[fIndex]
                        var fitem = {
                            name: formula.name,
                            expr: formula.expr
                        }
                        //fitem.code = formula.code ? formula.code : this.code
                        formulas.push(fitem)
                    }
                    
                    var item = {
                        title: doc.title,
                        report_date: doc.reportDate,
                        formulas: formulas
                    }
                    if (doc.date != undefined) {
                        item.start_time = doc.date[0].format('YYYY-MM-DD')
                        item.end_time = doc.date[1].format('YYYY-MM-DD')
                    }
                    docs.push(item)
                }
                var that = this
                this.axios.request({
                    url: "/stock/report/compute",
                    method: 'post',
                    data: {
                        docs: docs
                    }
                }).then(res=> {
                    if (res.code != 0) {
                        var msg = res.msg
                        if (res.code == 20002) {
                            var str = ""
                            for (var key in res.data) {
                                str += res.data[key] + ";"
                            }
                            msg += "(" + str + ")"
                        }
                        message.error(msg)
                        
                        return
                    }
                    var reports = res.data.reports
                    var charts = []
                    for (var repIndex in reports) {
                        var series = []
                        for (var fIndex in reports[repIndex].formulas) {
                            series.push({
                                name: reports[repIndex].formulas[fIndex].name,
                                type: that.docs[repIndex].formulas[fIndex].chartType,
                                data: reports[repIndex].formulas[fIndex].data
                            })
                        }

                        var xAxisData = reports[repIndex].dates
                        charts.push({
                            title: {
                                text: reports[repIndex].title,
                            },
                            xAxis: {
                                data: xAxisData
                            },
                            series: series
                        })
                    }
                    that.charts = charts
                })
            }
        },
        watch: {
        },
        computed: {
        },
        filters: {
        }
    }
</script>
<style src="./report.css" lang="css" scoped></style>
