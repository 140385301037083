import { createWebHistory, createRouter } from 'vue-router'
import { routes } from './routes.js'
import { beforeEach } from './guard.js'

/**
 * myRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
 var myRoutes = routes

const files = require.context('@/pages/', true, /.(vue|json)$/) 

const vueReg = RegExp(/.vue$/)
const pathKeys = files.keys()
pathKeys.forEach(key=> {
  if (!key.match(vueReg)) {
    return
  }
  var route
  const jsonKey = key.replace(/.vue$/, ".json")

  if (pathKeys.indexOf(jsonKey) != -1) {
    const jsonData = files(jsonKey)
    route = jsonData.route
  } else {
    route = {
      //path: path,
      //component: files(key).default,
      hidden: true,
    }
  }

  if (!("path" in route)) {
    const path = key.replace(/(^\.|(\/[^\/]*$))/g, "")
    if (path == "") {
      return
    }
    route.path = path
  }
  route.component = files(key).default
  myRoutes.push(route)
})

console.log(myRoutes)


const router = createRouter({
  history: createWebHistory(), // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: myRoutes
})

router.beforeEach(beforeEach)

export default router
