<template>
    <div v-if="format == 'footer'">
    </div>
    <div v-else>
        <a :href="link" target="_blank" class="number">{{number}}</a>
    </div>
</template>
  
<script>
export default {
    name: 'Icp',
    data() {
        return {
        link: this.$store.state.icp.link,
        number: this.$store.state.icp.number
        }
    },
    props: {
        format: {
            type: String
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .number {
        color: #999;
    }
</style>