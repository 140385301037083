<template>
  <div style="width: 500px;">
  </div>
  <div style="padding: 30px">
    <router-link  v-for="(item, key) in list" :key="key" :to="item.uri" class="item">
      <a-card :title="item.title" hoverable class="card">
        <p v-for="cp in item.content">{{cp}}</p>
      </a-card>
    </router-link>
    <div class="clear"></div>
  </div>
  <div class="footer"><icp></icp></div>
</template>

<script>
import { Card } from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import Icp from '@/components/icp'

export default {
  name: 'Home',
  components: {
    ACard: Card,
    Icp
  },
  data() {
    return {
      list: [
        {
          title: "图片浏览器",
          uri: "/image_reader",
          content: ["可实现图片的滚动或分页浏览", "本地看相册、看漫画"]
        },
        {
          title: "报表",
          uri: "/report",
          content: ["自定义报表图表"]
        },
        {
          title: "智能问答",
          uri: "/chat",
          content: ["智能机器人你问我答"]
        }
      ]
    }
  }
}
</script>
<style scoped>
  .item {
    float:left;
    margin-right: 30px;
  }
  .card {
    width: 300px;
  }
  .clear {
    clear: both;
  }
  .footer {
    text-align: center;
  }
</style>

