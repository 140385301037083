<template>
    <div id="container">
      <robot-chat boxHeight="90vh"></robot-chat>
    </div>
  </template>
  
<script>
  import RobotChat from '@/components/robot_chat'

  export default {
    name: "Chat",
    props: {
    },
    components: {
      RobotChat
    },
    data() {
      return {
      };
    },
  };
</script>

<style src="./chat.css" lang="css" scoped></style>
  