import { createStore } from 'vuex'

// 创建一个新的 store 实例
const store = createStore({
  state () {
    return {
      icp: {
        link: 'https://beian.miit.gov.cn/',
        number: '备案号：粤ICP备2021091577号-1'
      },
      siteName: '浮槎空间',
      token: ""
    }
  },
  mutations: {
  }
})

export default store