import { createApp } from 'vue'
import App from './pages/App.vue'
import router from './router'
import store from './store'
import { axios } from './utils/request'
import VueAxios from 'vue-axios'

createApp(App)
.use(router)
.use(store)
.use(VueAxios, axios)
.mount('#app')